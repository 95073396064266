<template>
	<div>
		<div class="page-title">
			<a>
				<router-link to="/letter/index">
					<el-button size="mini" icon="el-icon-back" class="mr-3">返回</el-button>
				</router-link>
			</a>
			<span style="font-size: 20px;">发布信件</span>
		</div>
		<div class="bg-white p-3 m-2">
			<el-form ref="form" :model="form" :rules="rules" label-width="80px" class="w-50">
				<el-form-item label="标题" prop="title">
					<el-input v-model="form.title" placeholder="标题"></el-input>
				</el-form-item>
				<el-form-item label="内容">
					<app-rich-text v-model="form.content"></app-rich-text>
				</el-form-item>
				<el-form-item label="类型">
					<el-radio v-model="form.type" :value="10" :label="10">
						全部单位
					</el-radio>
					<el-radio v-model="form.type" :value="20" :label="20">
						指定单位
					</el-radio>
				</el-form-item>
				<el-form-item v-if="form.type == 20" label="已选单位">
					<p class="m-0 p-0" v-for="(item,index) in this.form.unit">
						<span style="font-weight: 800;">{{item.first_unit_name}}</span>
						<template v-if="item.second_unit_name != ''">
							<span style="font-weight: 800;">- {{item.second_unit_name}}</span>
						</template>
						<template v-if="item.third_unit_name != ''">
							<span style="font-weight: 800;">- {{item.third_unit_name}}</span>
						</template>
						<el-button class="ml-3" @click="deleteUnit(index)" size="mini">删除</el-button>
					</p>
				</el-form-item>
				<el-form-item v-if="form.type == 20">
					<el-button @click="openDialog('form')" size="mini">选择单位</el-button>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="submit('form')" :loading="loading">提交</el-button>
				</el-form-item>
			</el-form>
		</div>
		<el-dialog title="添加单位" :visible.sync="dialogVisible" top="3vh" max-width="650px" height="60%">
			<el-select @change="handleFirstChange" v-model="first_id" placeholder="请选择支队">
				<el-option v-for="item in firstList" :key="item.unit_id" :label="item.unit_name" :value="item.unit_id">
				</el-option>
			</el-select>
			<el-select @change="handleSecondChange" v-model="second_id" placeholder="请选择大队">
				<el-option v-for="item in secondList" :label="item.unit_name" :key="item.unit_id" :value="item.unit_id">
				</el-option>
			</el-select>
			<el-select @change="handleThirdChange" v-model="third_id" placeholder="请请选择街道">
				<el-option label="请选择" :key="0" :value="0">
				</el-option>
				<el-option v-for="item in thirdList" :label="item.unit_name" :key="item.unit_id" :value="item.unit_id">
				</el-option>
			</el-select>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="addUnit()">添加</el-button>
				<el-button @click="dialogVisible = false">取消</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import appRichText from '@/components/app-rich-text.vue';
	export default {
		inject: ['layout'],
		components: {
			appRichText
		},
		data() {
			return {
				loading: false,
				firstList: [],
				secondList: [],
				thirdList: [],
				first_id: '',
				second_id: '',
				third_id: 0,
				first_unit_name: '',
				second_unit_name: '',
				third_unit_name: '',
				form: {
					title: '',
					type: 10,
					content: '',
					unit: [],
				},
				rules: {
					title: [{
						required: true,
						message: '请输入标题',
						trigger: 'change'
					}, ],
				},
				dialogVisible: false
			}
		},
		created() {
			this.getUnitList()
		},
		methods: {
			deleteUnit(index) {
				this.form.unit.splice(index, 1);
			},
			addUnit() {
				if (this.first_id <= 0) {
					this.$message({
						message: "请选择单位",
						type: 'success'
					});
					return false;
				}
				let unit_id = this.first_id;
				if (this.second_id > 0) {
					unit_id = this.second_id;
				}
				if (this.third_id > 0) {
					unit_id = this.third_id;
				}
				this.form.unit.push({
					first_id: this.first_id,
					second_id: this.second_id,
					third_id: this.third_id,
					unit_id: unit_id,
					first_unit_name: this.first_unit_name,
					second_unit_name: this.second_unit_name,
					third_unit_name: this.third_unit_name,
				});
				console.log(this.form.unit)
			},
			// 打开弹出层
			openDialog(item) {
				this.dialogVisible = true
			},
			handleFirstChange(first_id) {
				this.second_id = '';
				this.third_id = 0;
				this.second_unit_name = '';
				this.third_unit_name = '';
				for (let i = 0; i < this.firstList.length; i++) {
					if (this.firstList[i]['unit_id'] == first_id) {
						this.secondList = this.firstList[i].child;
						this.first_unit_name = this.firstList[i].unit_name;
					}
				}
			},
			handleSecondChange(second_id) {
				this.third_id = 0;
				this.third_unit_name = '';
				for (let i = 0; i < this.secondList.length; i++) {
					if (this.secondList[i]['unit_id'] == second_id) {
						this.thirdList = this.secondList[i].child;
						this.second_unit_name = this.secondList[i].unit_name;
					}
				}
			},
			handleThirdChange(third_id) {
				for (let i = 0; i < this.thirdList.length; i++) {
					if (this.thirdList[i]['unit_id'] == third_id) {
						this.third_unit_name = this.thirdList[i].unit_name;
					}
				}
			},

			getUnitList() {
				this.layout.showLoading()
				this.axios({
					token: true,
					params: {
						s: 'store/unit/index',
					},
					method: 'get',
				}).then(res => {
					this.layout.hideLoading()
					this.firstList = res.data.data
				}).catch(err => {
					this.layout.hideLoading()
				})
			},
			// 提交
			submit(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.loading = true;
						this.axios({
							token: true,
							params: {
								s: 'store/letter/add',
							},
							data: {
								letter: this.form
							},
							method: 'post',
						}).then(res => {
							this.loading = false;
							if (res.data.code == 1) {
								this.$message({
									message: res.data.msg,
									type: 'success'
								});
								// 跳转后台首页
								this.$router.push({
									name: 'letter/index'
								})
							} else {
								this.$message({
									message: res.data.msg,
									type: 'error'
								});
							}
						}).catch(err => {
							this.loading = false;
						})
					}
				})
			}
		}
	}
</script>

<style>
</style>
